import { render, staticRenderFns } from "./ShowShareCodeMobile.vue?vue&type=template&id=258bf51a&scoped=true"
import script from "./ShowShareCodeMobile.vue?vue&type=script&lang=js"
export * from "./ShowShareCodeMobile.vue?vue&type=script&lang=js"
import style0 from "./ShowShareCodeMobile.vue?vue&type=style&index=0&id=258bf51a&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "258bf51a",
  null
  
)

export default component.exports